

































































import { Component, Vue } from 'vue-property-decorator'
import SymbolName from '@/components/SymbolName.vue'
import freebieService, { HistoryDataItem } from '@/services/freebieService'
import { ContractSymbol } from '@/services/data'
import utility from '@/common/utility'
import DateText from '@/components/DateText.vue'

@Component({
  components: {
    DateText,
    SymbolName
  }
})
export default class HistoryData extends Vue {
  isLoading = false
  histories: HistoryDataItem[] = []
  dialogVisible = false
  selectedSymbol: ContractSymbol = null
  search = {
    start: '',
    end: ''
  }

  async mounted () {
    await this.loadData()
  }

  async loadData () {
    const res = await freebieService.getDownloadHistoryAsync()
    if (res.Result) {
      this.histories = res.Result
    } else {
      await this.$alert(res.Error)
    }
  }

  showDownload (his: HistoryDataItem) {
    this.selectedSymbol = his.Symbol
    this.search.start = his.FirstUpdate
    this.search.end = his.LastUpdate
    this.dialogVisible = true
  }

  async downloadData () {
    try {
      const blob = await freebieService.downloadLog(this.selectedSymbol.Symbol, this.search.start, this.search.end)
      utility.download(`option${this.selectedSymbol.Symbol}.csv`, blob, 'application/octet-stream')
    } catch (e) {
      await this.$alert(e)
    }
  }
}
